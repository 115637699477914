import React from "react";
import axios from "axios";
import { autobind } from "react-decoration";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import { page } from "components/page";
import SearchBlock from "components/search/SearchBlock";
import BookListBlock from "components/common/BookListBlock";
import HotRankingsBlock from "components/common/HotRankingsBlock";
import RelatedBlock from "components/common/RelatedBlock";
import NewBooksBlock from "components/common/NewBooksBlock";
import FeatureBlock from "components/common/FeatureBlock";
import ThemeBlock from "components/common/ThemeBlock";
import HotEBooksBlock from "components/common/HotEBooksBlock";
import TopNewsBlockComp from "components/resource/news/TopNewsBlockComp";
import HotWeb20Block from "components/common/HotWeb20Block";
import ForceNewsLightBox from "components/resource/news/ForceNewsLightBox";

@withI18next(["common"])
@page
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    let { domain } = props.appStore;
    this.state = {
      domain
    };
  }

  componentDidMount() {
    this.checkForceNews();
  }

  UNSAFE_componentWillReceiveProps(props) {
    let forceNewsSession = props.readerStore.forceNews || "";
    if (forceNewsSession !== "1") {
      this.checkForceNews();
    }
  }

  @autobind
  checkForceNews() {
    let { appStore, readerStore, search } = this.props;
    let { indexdata } = appStore;
    if (indexdata === null || indexdata === undefined) {
      return null;
    }
    let { forceNews } = indexdata;
    if (forceNews === null || forceNews === undefined) {
      return null;
    }
    let { domainList } = forceNews;
    let { session } = readerStore;
    let params = { ...search };
    let { domain = "" } = params;
    domain = domain !== "" ? domain : "default";
    let forceNewsDomain = {
      domain: "default",
      data: null,
    };
    if (domainList) {
      domainList.map((m) => {
        if (m.domain === domain) {
          forceNewsDomain = m;
        }
        return "";
      });
    }
    let forceNewsSession = session.forceNews || "";
    if (forceNewsSession !== "1") {
      axios
        .get("/api/jumperrwdWs/setSession?key=forceNews&value=1")
        .then(() => {
          this.props.readerStore.syncSessionCookie().then(() => {
            if (forceNewsDomain.data && this.forceNewsLightBox) {
              this.forceNewsLightBox.popup();
            }
          });
        });
    }
  }

  render() {
    let { i18n, search, appStore } = this.props;
    let { index, globalConfig } = appStore;
    let tabbar = globalConfig["jumper.common.tabbar.default"];
    if (tabbar === "collections") {
      tabbar = "hold";
    } else if (tabbar === "fullsearch") {
      tabbar = "integration";
    }
    let params = { ...search };
    let { domain = "" } = params;
    let searchBlockType = search.searchBlockType || tabbar;
    let { language } = i18n;
    let tImageSrc = "",
      defaultTSrc = "/file/images/logo.png";
    if (language === "zh") {
      tImageSrc = "/file/images/logo.png";
    } else if (language === "en") {
      tImageSrc = "/file/images/logo_en.png";
    }
    if (tImageSrc === "") {
      tImageSrc = defaultTSrc;
    }
    domain = domain !== "" ? domain : "default";
    let indexDomain = {
      domain: "default",
      mpLogoShow: true,
      blocks: [
        {
          type: "bookList",
          show: false,
        },
        {
          type: "hotRankings",
          show: false,
        },
        {
          type: "related",
          show: false,
        },
        {
          type: "newBooks",
          show: false,
        },
        {
          type: "feature",
          show: true,
        },
        {
          type: "theme",
          show: false,
        },
        {
          type: "hotEBooks",
          show: false,
        },
        {
          type: "hotWeb20",
          show: false,
        },
      ],
      topNewsBlockShow: false,
    };
    if (index.domainList) {
      index.domainList.map((m) => {
        if (m.domain === domain) {
          indexDomain = m;
        }
        return "";
      });
    }
    return (
      <Layout {...this.props}>
        <div className="main mp" id="center">
          <a
            className="accesskey"
            href="javascript:;"
            id="aC"
            accessKey="C"
            title={this.props.t("jumperrwd.common.mainContenctArea")}>
            :::
          </a>
          {indexDomain.topNewsBlockShow && <TopNewsBlockComp />}
          <SearchBlock
            searchType={searchBlockType}
            mpLogoShow={indexDomain.mpLogoShow}
            tImageSrc={tImageSrc}
            isIndex={true}
          />
          {indexDomain.blocks.map((block) => {
            switch (block.type) {
              case "bookList":
                return <>{block.show && <BookListBlock />}</>;
              case "hotRankings":
                return <>{block.show && <HotRankingsBlock />}</>;
              case "related":
                return <>{block.show && <RelatedBlock />}</>;
              case "newBooks":
                return <>{block.show && <NewBooksBlock />}</>;
              case "feature":
                return <>{block.show && <FeatureBlock />}</>;
              case "theme":
                return <>{block.show && <ThemeBlock />}</>;
              case "hotEBooks":
                return <>{block.show && <HotEBooksBlock />}</>;
              case "hotWeb20":
                return <>{block.show && <HotWeb20Block />}</>;
              default:
            }
            return "";
          })}
        </div>
        <ForceNewsLightBox
          {...this.props}
          lightboxClass="forcenews_lightbox"
          blockClass="forcenewsblock binding"
          ref={(c) => {
            this.forceNewsLightBox = c;
          }}
        />
      </Layout>
    );
  }
}

export default IndexPage;
