import React from "react";
import LightBox from "components/common/LightBox";
import ReactHtmlParser from "react-html-parser";
import Link from "lib/Link";

@LightBox
class ForceNewsLightBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { appStore, search } = this.props;
    let { indexdata } = appStore;
    if (indexdata === null || indexdata === undefined) {
      if (this.props.display === "block") {
        this.props.close();
      }
      return null;
    }
    let { forceNews } = indexdata;
    if (forceNews === null || forceNews === undefined) {
      if (this.props.display === "block") {
        this.props.close();
      }
      return null;
    }
    let { domainList } = forceNews;
    let params = { ...search };
    let { domain = "" } = params;
    domain = domain !== "" ? domain : "default";
    let forceNewsDomain = {
      domain: "default",
      data: null,
    };
    if (domainList) {
      domainList.map((m) => {
        if (m.domain === domain) {
          forceNewsDomain = m;
        }
        return "";
      });
    }
    if (!forceNewsDomain.data) {
      if (this.props.display === "block") {
        this.props.close();
      }
      return null;
    }
    return (
      <div className="forcenews_form">
        {forceNewsDomain.data.map((d, k) => {
          return (
            <>
              {k > 0 && <hr />}
              <h3>{ReactHtmlParser(d.subject)}</h3>
              <ul>
                <li>{this.props.t("hyint.common.news.date")}：{d.onlinedate}</li>
              </ul>
              <p>{ReactHtmlParser(d.content)}</p>
              {d.url && (
                <p>
                  {this.props.t("hyint.common.table.relurl")}：
                  {d.url.split("\n").map((items) => {
                    let data = items.split(";");
                    if (data[0] === "1") {
                      return (
                        <p>
                          <Link href={data[2]} target="_blank">
                            {data[1]}
                          </Link>
                        </p>
                      );
                    } else {
                      return (
                        <p>
                          <Link href={data[2]}>{data[1]}</Link>
                        </p>
                      );
                    }
                  })}
                </p>
              )}
              {d.attachs && d.attachs.length > 0 && (
                <p>
                  {this.props.t("jumperrwd.download.relatedDownload")}：
                  {d.attachs.map((item) => {
                    return (
                      <p>
                        <Link
                          target="_blank"
                          href={"/file/downloadFile/" + item.attachPath}>
                          {item.showName}
                        </Link>
                      </p>
                    );
                  })}
                </p>
              )}
              <ul>
                <li>
                  {this.props.t("hyint.common.table.credate")}：{d.cretime}
                </li>
                <li>
                  {this.props.t("hyint.common.table.upddate")}：{d.updtime}
                </li>
              </ul>
            </>
          );
        })}
      </div>
    );
  }
}

export default ForceNewsLightBox;
