import React from "react";
import client from "lib/ApolloClient";
import { withI18next } from "lib/withI18next";
import { page } from "components/page";
import { ApolloProvider, Query } from "react-apollo";
import gql from "graphql-tag";
import GraphqlFragment from "lib/GraphqlFragment";
import Link from "lib/Link";

const getNewsList = gql`
  query getNewsList($searchForm: NewsForm){
    getNewsList(Input: $searchForm){
      ${GraphqlFragment.defaultLayoutReturn}
    }
  }
  ${GraphqlFragment.defaultLayoutFragment} 
`;

@withI18next(["common"])
@page
class TopNewsBlockComp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let { appStore } = this.props;

    let newMod = appStore.globalConfig["jumper.news.mod"];
    let moreURL = newMod === "0" ? "/news" : "/newsV2";

    return (
      <ApolloProvider client={client.jumperrwdClient}>
        <Query
          displayName="graphql"
          query={getNewsList}
          fetchPolicy={"no-cache"}
          variables={{
            searchForm: {
              pageNo: "1",
              limit: 1,
            },
          }}>
          {({ loading, data, refetch, error }) => {
            if (error) return "error";
            if (loading) return "Loading...";

            if (data.getNewsList !== null && data.getNewsList) {
              var newsList = [];

              data.getNewsList.list.values.map((item) => {
                let obj = {};
                item.ref.forEach(function (data, i) {
                  if (data.key === "subject") {
                    obj.subject = data.value;
                  } else if (data.key === "onlinedate") {
                    obj.onlinedate = data.value;
                  } else if (data.key === "sn") {
                    obj.sn = data.value;
                  }
                });
                newsList.push(obj);
                return "";
              });

              return (
                <div className="news_top">
                  <h2>{this.props.t("jumperrwd.common.news")}</h2>
                  {newsList && newsList.length > 0 && (
                    <>
                      <ul>
                        {newsList.map((item) => {
                          return (
                            <li>
                              <span>{item.onlinedate}</span>
                              <Link href={`/newsDetail?sn=${item.sn}`}>
                                {item.subject}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                      <Link href={moreURL} className="more">
                        {this.props.t("jumperrwd.common.seeMore")}
                      </Link>
                    </>
                  )}
                </div>
              );
            } else return "Error...";
          }}
        </Query>
      </ApolloProvider>
    );
  }
}

export default TopNewsBlockComp;
